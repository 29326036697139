$font: 'MetaMediumRoman';

div.csr_phone_number {
  display: none;
}

.brand-id-Aveda {
  font-family: $font;
  font-size: 15px;
  padding: 10px;
  h3 {
    font-size: 50px;
    text-decoration: underline;
    margin: 0;
  }
  h4 {
    font-size: 30px;
    font-weight: normal;
  }
}

.csrHide {
  display: none;
}

#csr_header_holder {
  position: absolute;
  top: 65px;
  #{$ldirection}: 0;
  width: 100%;
  z-index: 9999;
  #csr_header {
    position: absolute;
    width: 750px;
    height: auto;
    border: 5px $color-red solid;
    padding: 10px;
    font-size: 11px;
    background-color: $white;
    a {
      font-size: 1.25em;
      display: block;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .csr_table {
      width: 100%;
      margin: 0;
      table-layout: auto;
      line-height: normal;
      td {
        border-#{$ldirection}: 1px $color-red solid;
        padding: 0 20px;
        border-bottom: none;
      }
      #csr_info_box {
        border-#{$ldirection}: none;
      }
      #csr_dragger {
        background: $color-red;
        width: 40px;
        border: none;
        cursor: move;
        padding: 0;
      }
    }
  }
}

.shadow {
  -webkit-box-shadow: 3px 3px 4px 5px $color-medium-gray;
  -moz-box-shadow: 3px 3px 4px 5px $color-medium-gray;
  box-shadow: 3px 3px 4px 5px $color-medium-gray;
}
