.product-full {
  &__quote {
    @include font-smoothing;
    display: none; // hide for mobile
    top: $spacing--large;
    position: absolute;
    text-align: #{$ldirection};
    width: 38%;
    max-width: 300px;
    font-size: 26px;
    line-height: get-line-height(26px, 32px);
    font-weight: 300;
    @include breakpoint($portrait-up) {
      display: block; // show portrait up only
      z-index: 1;
      font-size: 22px;
    }
    @include breakpoint($landscape-down) {
      width: 100%;
      max-width: 100%;
      position: static;
      top: 0;
      #{$ldirection}: 0;
    }
    @include breakpoint($large-up) {
      #{$ldirection}: -85px;
    }
  }
}
