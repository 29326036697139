//Regional Color variables
$color-lighter-brown: #ff8c00;

//Adaptive placeholder Mixins
@mixin setdefault_height {
  height: 9.5em;
}

@mixin setdefault_inputmargin {
  margin-top: 9.5em;
}

@mixin setdefault_beforemargin {
  margin: 1em;
}

@mixin setdefault_inputpadding {
  padding-top: 1em;
}

#viewcart,
#samples {
  .product_name,
  .product-name {
    a {
      text-decoration: none;
    }
  }
}

.viewcart-buttons-panel {
  margin: 15px 0 56px;
  padding: 0;
  text-align: center;
  .go-shopping {
    background: $white;
    padding: 9px 19px 8px 19px;
    min-width: 116px;
    max-width: 100%;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: $color-off-black;
    border: 1px solid $color-off-black;
    &:hover {
      cursor: pointer;
      background: $color-off-black;
      color: $white;
      text-decoration: none;
    }
  }
  .continue-shopping {
    text-transform: uppercase;
    text-decoration: underline;
    float: left;
  }
  .continue-buttons {
    .continue-shopping {
      font-weight: normal;
      margin: 10px 0 0;
      letter-spacing: 1px;
      display: inline-block;
      float: left;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
  .continue-checkout-btns {
    float: right;
    margin: 10px 0 0;
    .continue-checkout {
      letter-spacing: 1px;
      text-decoration: none;
      background: $color-off-black;
      color: $white;
      padding: 9px 19px 8px 19px;
    }
  }
}

section.viewcart {
  .panel {
    margin: 15px 0 35px;
    header {
      padding: 20px 0 4px;
      position: relative;
      margin: 0.5em 0;
      width: 100%;
      font-weight: bold;
      h2 {
        display: inline-block;
        margin: 0 0 0.5em 0;
        padding: 0;
        text-transform: uppercase;
        font-size: 27px;
      }
    }
  }
  .checkout-header {
    #top-viewcart-buttons,
    .continue-shopping {
      display: none;
    }
  }
  .viewcart-panel {
    border-top: none;
    .viewcart-header {
      border-bottom: 1px solid $light-gray;
      padding-bottom: 30px;
    }
    .viewcart-panel__content {
      .signin-to-see-cart {
        a {
          text-decoration: underline;
        }
      }
    }
  }
  .column {
    &.left {
      &.checkout__content {
        .viewcart-header {
          h2 {
            font-size: 38px;
            text-align: left;
            width: 90%;
            float: left;
          }
          .item-count {
            display: inline-block;
            width: 10%;
          }
        }
        .panel {
          header {
            border-top: 1px solid $light-gray;
          }
        }
        @include breakpoint($landscape-up) {
          border-top: 0;
          .checkout {
            &-header {
              border-bottom: 1px solid $color-light-gray;
            }
          }
          .single-message {
            color: $color-red;
          }
          .viewcart-buttons-panel {
            .continue-buttons {
              text-align: right;
              .go-shopping {
                text-align: center;
                margin: 0 auto;
                display: block;
                width: 32%;
              }
              .btn-primary {
                background: $color-off-black;
                color: $white;
                height: 36px;
                line-height: 36px;
                min-width: 110px;
                padding: 0;
                display: inline-block;
                text-align: center;
                font-size: 15px;
                text-transform: uppercase;
                text-decoration: none;
              }
            }
          }
          .promo-bca-panel {
            .checkout__panel-content {
              float: left;
              width: 50%;
              .promo_bca_logo {
                width: auto;
                float: left;
                height: auto;
              }
            }
          }
          #viewcart-panel {
            .cart-header {
              .cart-header__title {
                color: $gray;
                float: left;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                padding-bottom: 9px;
                &--products {
                  width: 50%;
                }
                &--price {
                  width: 18%;
                }
                &--qty {
                  width: 13%;
                }
                &--total {
                  padding-left: 52px;
                  width: 19%;
                }
              }
            }
            .cart-items.products {
              .cart-item {
                margin: 0;
                padding: 25px 0 70px 45px;
              }
            }
          }
        }
        #promo-bca-panel {
          .checkout-panel__content {
            .promo-bca-content__description {
              font-size: 12px;
            }
            .checkout-panel {
              &__header {
                margin-top: 0px;
              }
              &__heading {
                margin-bottom: 0px;
              }
            }
          }
          .checkout-panel {
            .promo-bca-panel__title {
              margin-bottom: 0px;
            }
          }
          .promo_bca_note {
            display: none;
          }
        }
      }
    }
  }
}

.promo-panel {
  .single-message {
    color: $red;
  }
}

#viewcart {
  @include breakpoint($landscape-down) {
    .checkout.viewcart.panel {
      height: auto;
    }
    #sticky-checkout-button-overlay,
    .cart-header.clearfix {
      display: none;
    }
    .cart-items.products {
      .replenishment.cart-item__replenishment {
        right: 0;
      }
    }
    a.continue-checkout {
      background: $color-off-black;
      color: $white;
      height: 36px;
      line-height: 36px;
      min-width: 110px;
      padding: 0;
      display: inline-block;
      text-align: center;
      font-size: 15px;
      text-transform: uppercase;
      text-decoration: none;
      width: 100%;
      margin-bottom: 10px;
    }
    a.paypal-checkout {
      width: 100%;
      img {
        padding-top: 0;
      }
    }
  }
  .order-summary__content {
    .order-summary__shipping-label {
      display: none;
    }
    .order-summary__shipping-value {
      margin-top: 1.5em;
    }
  }
  .order-summary__shipping-content {
    select {
      background-color: $white;
    }
    a.ship-method__information {
      margin: 10px 0;
      display: inline-block;
      text-transform: uppercase;
    }
  }
  .cart-items {
    &.products {
      .cart-header {
        .products,
        .column {
          color: $gray;
          float: left;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          padding-bottom: 9px;
        }
        .products {
          width: 55%;
        }
        .price {
          width: 15%;
        }
        .qty {
          width: 13%;
        }
        .total {
          width: 17%;
          text-align: center;
        }
      }
      .cart-item {
        border-top: 1px solid $light-gray;
        position: relative;
        border-bottom: 0px;
        &.product {
          margin: 0;
          padding: 25px 0 35px 45px;
          border-top: 1px solid $light-gray;
          position: relative;
          min-height: auto;
        }
        &__thumb {
          margin-right: 0;
          width: 15%;
          float: left;
          padding-left: 0;
          margin-top: 7px;
        }
        &__desc {
          padding-right: 2%;
          width: 35%;
          padding-bottom: 30px;
          word-wrap: break-word;
          line-height: 21px;
          float: left;
          padding-left: 0;
          margin-top: 7px;
          min-height: 100px;
        }
        &__price {
          width: 18%;
          float: left;
          padding-left: 0;
          margin-top: 7px;
          &--mobile-only {
            display: none;
          }
        }
        &__qty {
          width: 13%;
          float: left;
          padding-left: 0;
          margin-top: 7px;
          .select-box__label,
          .select-box__options {
            min-width: 77px;
          }
        }
        &__total {
          padding-left: 52px;
          width: 19%;
          float: left;
          margin-top: 7px;
        }
        &__replenishment {
          width: 50%;
          float: left;
          padding-left: 0;
          position: absolute;
          right: -80px;
          top: 70px;
          .alter_replenishment_form--container {
            width: 80%;
          }
          .replen_notice {
            font-size: 12px;
            line-height: 15px;
            margin: 5px 10px 0 0;
          }
          a.overlay-link {
            color: $color-light-grey-text;
          }
        }
        &__remove-form {
          a.remove_link {
            bottom: 60px;
            top: inherit;
            right: inherit;
            line-height: normal;
            letter-spacing: 0.05em;
            text-decoration: underline;
            position: absolute;
          }
        }
        &__add-to-favorites {
          display: none;
        }
        &-vskus + .cart-item__remove-form {
          a.remove_link {
            bottom: 45px;
          }
        }
        .price-label,
        .qty-label {
          display: none;
        }
      }
    }
  }
  .viewcart {
    #recommended-products-panel {
      .recommended-product-items {
        .recommended-item {
          height: auto !important;
          .addtobag {
            bottom: 0px;
            position: relative;
          }
          &__button {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            padding: 9px 19px 8px 19px;
            display: block;
            text-decoration: none;
            text-transform: uppercase;
            text-align: center;
            min-width: 116px;
            max-width: 100%;
            font-size: 15px;
            line-height: 16px;
            background: $white;
            color: $color-off-black;
            border: 1px solid $color-off-black;
            -webkit-appearance: none;
            border-radius: 0;
            position: relative;
            top: 1px;
            margin: 20px 0px;
          }
        }
        .description {
          &.column {
            float: left;
            clear: left;
            width: auto;
          }
        }
      }
    }
  }
  #cboxLoadedContent {
    padding-bottom: 0px;
    .continue-button-wrapper.add-button {
      padding-bottom: 20px;
    }
  }
  form#cart {
    select {
      width: 77px;
    }
  }
  .paypal-checkout {
    img {
      vertical-align: top;
    }
  }
  a {
    &.paypal-checkout {
      height: 37px;
      line-height: 36px;
      min-width: 124px;
      padding: 0;
      display: inline-block;
      text-align: center;
      font-size: 15px;
      text-transform: uppercase;
      text-decoration: none;
    }
  }
  #order-summary-panel {
    .ship-method {
      .select-box {
        width: 45%;
      }
    }
  }
}

.order-summary-panel {
  position: relative;
  margin-bottom: 0px !important;
  header {
    font-size: 18px;
    margin-bottom: 0;
  }
  .label {
    text-transform: uppercase;
    width: 85%;
    float: left;
    margin-bottom: 1.5em;
  }
  .value {
    color: $gray;
    width: 15%;
    float: right;
    font-weight: normal;
    text-align: left;
  }
  .total {
    color: $gray;
    border-top: 1px solid $light-gray;
    font-weight: bold;
    margin-bottom: 0;
    padding: 1em 0;
    &.value {
      color: $color-off-black;
    }
  }
}

.offer-code-panel {
  border-bottom: 1px solid $light-gray;
  .offer-code-panel__content {
    display: block;
    .offer-code__input {
      padding: 0;
      float: left;
      width: 61%;
    }
    .offer-code__submit {
      padding: 0;
      width: 28%;
      position: relative;
      bottom: 1px;
      right: 1px;
      float: left;
    }
  }
  form#offer_code {
    margin: 20px 0;
    input {
      width: 100%;
    }
  }
  em {
    display: block;
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
    text-transform: uppercase;
    &:hover {
      color: $gray;
    }
  }
  .offer-code__byline {
    display: inline-block;
    font-weight: 700;
    text-transform: none;
    letter-spacing: 0;
    cursor: auto;
  }
}

.delivery_options__content {
  .select-box {
    width: 100%;
  }
  select {
    padding: 0;
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.need-help-panel.panel {
  border-top: none;
  margin-left: 2px;
  .content {
    .phone,
    .email {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding: 9px 19px 8px 19px;
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;
      min-width: 116px;
      max-width: 100%;
      font-size: 15px;
      line-height: 16px;
      background: $white;
      color: $color-off-black;
      border: 1px solid $color-off-black;
      margin-bottom: 10px;
      width: 100%;
      .text {
        display: none;
      }
    }
  }
  a {
    &.text_link {
      &.email {
        padding: 9px 19px 8px 19px;
        display: inline-block;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        min-width: 116px;
        max-width: 100%;
        font-size: 15px;
        line-height: 16px;
        background: $white;
        color: $color-off-black;
        border: 1px solid $color-off-black;
        width: 100%;
        &:hover {
          cursor: pointer;
          background: $color-off-black;
          color: $white;
          text-decoration: none;
        }
      }
    }
  }
}

.tab-switcher {
  margin-top: 5%;
  ul.tab-bar {
    li {
      margin-right: 4px;
      text-transform: uppercase;
      border: 0 solid $light-gray;
      border-width: 1px 1px 0 1px;
      cursor: pointer;
      margin: 0 4px 0 0;
      float: left;
      a {
        border-width: 0px;
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 14px;
        background-color: $white;
        text-align: center;
        padding: 10px 4px;
        &.active {
          background-color: $color-light-linen;
          color: $black;
        }
      }
      &:first-child {
        width: 70px;
      }
      &:nth-child(2) {
        text-align: center;
        width: 90px;
      }
      &:last-child {
        text-align: center;
        width: 123px;
        margin-right: 0;
      }
    }
  }
  .tab-content {
    border: 1px solid $light-gray;
    clear: both;
    .tab-pane {
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: opacity 1s ease;
      &.active {
        display: block;
        opacity: 1;
        height: auto;
      }
      .panel {
        border-top: 0px;
        margin-top: 0px;
        .product-img {
          float: left;
          text-align: center;
          overflow: hidden;
          width: auto;
        }
        .details {
          float: right;
          width: 59%;
          margin-left: 0px !important;
          .product-name {
            text-transform: capitalize;
          }
        }
      }
      .content {
        padding: 1em 1em 0;
        display: block !important;
      }
    }
    #favorites-panel,
    #past-purchases-panel,
    #samples-panel {
      margin-bottom: 0;
      padding-bottom: 0;
      position: relative;
      .instructions,
      .size,
      .prod-price {
        display: none;
      }
      ul {
        &.product-list {
          max-height: 500px;
          overflow: auto;
          .product {
            height: 13em;
            .product-img {
              max-width: 94px;
            }
          }
        }
      }
      .btn {
        background: transparent;
        border: 1px solid $color-off-black;
        color: $color-off-black;
        letter-spacing: 0.04em;
        padding: 0 15px;
        height: 36px;
        display: inline-block;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        min-width: 116px;
        max-width: 100%;
        font-size: 15px;
        line-height: 36px;
        cursor: pointer;
        &.selected {
          background: $color-off-black;
          color: $white;
        }
      }
    }
    #samples-pane {
      .samples-panel {
        .add-button {
          display: none;
        }
        .samples {
          padding-top: 0;
        }
        .product-list {
          li.product {
            margin: 0;
            height: auto;
            border-spacing: 0.4em;
            position: relative;
            &:first-child {
              border-top: none;
            }
          }
        }
      }
      .product-img {
        max-width: 94px;
        img {
          width: 87px;
          height: 147px;
        }
        .view-larger {
          display: none;
        }
      }
      .details {
        input {
          padding-top: 0;
          height: auto;
          &.sample-select {
            float: left;
            margin-right: 0.3em;
          }
        }
      }
      ul.skus {
        margin: 5px 0px 20px 0px;
        li {
          margin-bottom: 0.5em;
          &:last-child {
            border-bottom: none;
          }
        }
      }
      .continue-with-no-sample-btn {
        display: none;
      }
      a:hover {
        background: $color-off-black;
        color: $white;
      }
    }
  }
}

.links-panel {
  ul.links_list {
    li.link {
      font-size: 14px;
      margin: 0 0 5px 0;
      letter-spacing: 0.08em;
      a {
        text-decoration: underline;
        text-transform: uppercase;
      }
    }
  }
}

.pg_wrapper {
  &.samples-page {
    position: relative;
    margin: 0 auto 40px;
    max-width: 1024px;
    z-index: 1;
    font-size: 14px;
    margin-top: 124px;
    .samples-buttons.top {
      padding-top: 20px;
      margin-bottom: 10px;
      overflow: hidden;
      float: right;
    }
    .return-link {
      text-align: left;
      padding-top: 5px;
      float: left;
      width: auto;
    }
    .add-button {
      float: left;
      padding-left: 25px;
      .form-submit {
        top: 0;
      }
    }
    ul.product-list {
      padding-top: 20px;
      margin-top: 0;
      overflow: hidden;
      li.product {
        min-height: 470px;
        margin: 0 0 30px 2.2%;
        float: left;
        width: 22.6%;
        padding: 18px 0 10px 0;
        position: relative;
        &.first {
          margin-left: 0;
        }
        .product-img {
          padding-right: 0;
          float: none;
          margin-bottom: 20px;
          .view-larger {
            display: none;
          }
        }
        .details {
          float: none;
          width: 100%;
          text-align: left;
          line-height: 1.4;
          ul.skus {
            bottom: 0;
            position: absolute;
          }
        }
      }
    }
    .samples-buttons.bottom {
      float: right;
      padding-top: 20px;
      margin-bottom: 10px;
      overflow: hidden;
      .continue-with-no-sample-btn {
        float: left;
        margin-left: 10px;
        padding: 9px 19px 8px 19px;
        background: $color-off-black;
        color: $white;
      }
    }
    .viewcart-buttons-panel {
      display: none;
    }
  }
}

.samples-overlay {
  .samples-panel {
    .header {
      display: none;
    }
    .content {
      height: auto;
      padding: 1em;
      .add-button {
        display: inline-block;
        padding-top: 20px;
        padding-right: 25px;
        float: right;
      }
      .samples {
        float: left;
        width: 100%;
      }
      ul.product-list {
        & > li {
          float: left;
          padding: 20px 0px;
          position: relative;
          margin: 1em 0;
          width: 50%;
          min-height: 260px;
          .product-img {
            width: 85px;
            float: left;
            max-width: 108px;
            text-align: center;
            img {
              width: 87px;
              height: 147px;
            }
            a {
              clear: left;
            }
          }
          a:hover {
            background: $color-off-black;
            color: $white;
          }
          .sample-select-button {
            background: transparent;
            border: 1px solid $color-off-black;
            color: $color-off-black;
            letter-spacing: 0.04em;
            padding: 0 15px;
            height: 36px;
            display: inline-block;
            text-decoration: none;
            text-transform: uppercase;
            text-align: center;
            min-width: 116px;
            max-width: 100%;
            font-size: 15px;
            line-height: 36px;
            &.selected {
              background: $color-off-black;
              color: $white;
            }
          }
        }
        .details {
          float: right;
          width: 59%;
        }
      }
      .prev-next {
        display: none;
      }
      .view-larger {
        display: none;
      }
    }
  }
}

#mobile-breadcrumb {
  display: none;
}

body#index {
  height: auto;
  .checkout {
    .shipping-panel {
      #continue-btn {
        float: right;
      }
      .form-item {
        input[type='tel'] + label:before,
        input[type='text'] + label:before,
        input[type='password'] + label:before,
        input[type='email'] + label:before {
          content: attr(data-required) attr(placeholder);
        }
      }
      .title-fields {
        .error {
          color: $color-red;
        }
      }
      .error {
        .select-box {
          &__label {
            border-color: $color-red;
            color: $color-red;
            &::after {
              color: $color-red;
            }
          }
        }
      }
    }
  }
  .column {
    &.right {
      display: none;
    }
  }
  #checkout_registration_panel {
    .checkout-password-label {
      display: none;
    }
  }
  #sign-in-paypal-panel {
    .messages {
      padding-left: 10px;
    }
  }
  .giftwrap {
    input[type='text'].js-placeholder-mode + label {
      opacity: 1 !important;
    }
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    input::-webkit-input-placeholder {
      color: transparent;
    }
    input:-moz-placeholder {
      /* Firefox 18- */
      color: transparent;
    }
    input::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent;
    }
    input:-ms-input-placeholder {
      color: transparent;
    }
  }
  .sms-mobile {
    input[type='tel'].js-placeholder-mode + label {
      opacity: 1 !important;
    }
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    input::-webkit-input-placeholder {
      color: transparent;
    }
    input:-moz-placeholder {
      /* Firefox 18- */
      color: transparent;
    }
    input::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent;
    }
    input:-ms-input-placeholder {
      color: transparent;
    }
  }
  @include breakpoint($landscape-down) {
    .checkout__index-content {
      height: auto;
    }
    .checkout {
      .column.left.checkout__content {
        width: 100%;
      }
    }
    #shipping-panel {
      fieldset.qas {
        max-width: none;
      }
    }
    .order-summary__shipping-content {
      width: 40%;
    }
  }
  .sign-in-panel {
    .messages {
      [class*='error_offer_criteria_'] {
        display: none;
      }
    }
  }
  .order-summary__content {
    .order-summary__shipping-label {
      display: none;
    }
    .order-summary__shipping-value {
      margin-top: 1.5em;
    }
  }
  .order-summary__shipping-content {
    a.ship-method__information {
      margin: 5px 0;
      text-transform: uppercase;
      border-bottom: 0;
    }
  }
  .column.left.checkout__content {
    width: 100%;
    border-top: 0;
    .shipping-panel,
    .payment-panel {
      display: none;
    }
    .panel {
      > header {
        padding: 0 10px;
        cursor: pointer;
        display: block;
        width: 100%;
        font-weight: bold;
        clear: both;
        border-top: 1px solid $light-gray;
        margin-bottom: 15px;
        a.edit.btn-mini {
          float: none;
          margin: 15px 0 0 15px;
          position: relative;
          bottom: 4px;
          border-bottom: 1px solid $color-off-black;
        }
      }
      &.finished.shipping-panel {
        > header {
          border-top: none;
        }
      }
      &.collapsed {
        > header {
          border-top: none;
        }
      }
      &.shipping-panel.finished {
        > header {
          background: $color-light-linen;
        }
      }
    }
    .content {
      padding: 0 10px 20px 10px;
      .order-summary__shipping-content {
        padding: 0;
      }
    }
    input[type='checkbox'] {
      float: left;
      margin: 2px 0;
      width: auto;
      & ~ label {
        margin-left: 10px;
        width: 90%;
        clear: none;
        display: inline-block;
      }
    }
    .checkout__subtitle {
      min-height: 25px;
    }
    .gift-cards,
    .gift-options__content {
      .checkout__subtitle {
        font-size: 14px;
        letter-spacing: 0;
        font-weight: 700;
      }
    }
    .email-and-sms-promotions {
      margin-top: 5%;
    }
    #new-account-h,
    #return-account-h {
      .checkout__subtitle {
        min-height: 60px;
      }
    }
    #checkout_shipping_panel {
      .title-fields {
        label {
          width: auto;
          margin-right: 12px;
        }
      }
      .form-item {
        padding-right: 10px;
      }
      .checkout__subtitle {
        width: 100%;
      }
      .giftwrap {
        .giftwrap__is-gift {
          width: 100%;
          img {
            display: block;
          }
        }
        img {
          display: none;
        }
      }
    }
  }
  #need-help-panel {
    display: block;
  }
  .sign-in-panel {
    input {
      width: 100%;
    }
    .column {
      width: 50%;
      float: left;
      padding: 1em;
    }
    .content {
      padding: 0 10px 20px 10px;
    }
    #return-user {
      .example {
        display: none;
      }
    }
  }
  .checkout__sidebar {
    #shopping-bag-panel {
      header {
        h2 {
          float: left;
          margin: 15px 0 5px;
          padding: 0;
        }
      }
      .shopping-bag-panel__item-count {
        float: right;
        font-size: 14px;
        margin: 15px 0;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    .size-label {
      display: none;
    }
    .replenishment {
      margin-top: 15px;
      text-transform: capitalize;
      font-weight: normal;
    }
  }
  #viewcart-panel {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid $light-gray;
    background: none;
    overflow: auto;
    .viewcart-header {
      h2 {
        width: 80%;
      }
      .item-count {
        width: 20%;
      }
    }
    .cart-items {
      &.products {
        .cart-header {
          display: none;
        }
        .cart-item {
          clear: both;
          &:last-child {
            border-bottom: none;
          }
        }
        .column {
          padding: 0.8em 0.6em;
        }
        .thumb {
          float: left;
          width: 32%;
          min-height: 215px;
          margin-right: 10px;
        }
        .desc {
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 10px;
          float: left;
          width: 62%;
          .product_name {
            display: block;
          }
        }
        .qty {
          margin-bottom: 10px;
          float: left;
          .qty-label {
            display: inline-block;
          }
        }
        .total {
          margin-bottom: 10px;
          float: left;
          display: inline-block;
        }
        .price {
          display: none;
        }
      }
    }
  }
  .panel {
    &.collapsed {
      clear: both;
    }
    &.sign-in-panel.finished {
      header {
        background: none;
      }
    }
    .shipping-address,
    .billing-address,
    .billing-address-form-content {
      .form-item {
        padding: 0;
        margin-bottom: 20px;
        height: 35px;
        position: relative;
      }
    }
    .continue-button-wrapper {
      text-align: center;
      padding: 0;
      float: right;
      width: auto;
      margin: 20px 0;
      input {
        letter-spacing: 1px;
        background: $color-off-black;
        color: $white;
      }
    }
  }
  h2.shipping-panel__title + a.edit,
  h2.registration-panel__title + a.edit {
    margin: 15px 0 0 15px;
    text-transform: uppercase;
    min-width: 20px;
    border: none;
    padding: 0;
    font-size: 18px;
    color: $color-off-black;
    border-bottom: 1px solid $color-off-black;
    height: auto;
    line-height: normal;
  }
  section#shipping-panel {
    .form-item.state {
      .select-box__label {
        height: 44px;
        line-height: 44px;
      }
      .select-box {
        width: 100%;
      }
    }
    h2.shipping-panel__title {
      a#shipping-panel-edit {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }
    .sub-section {
      .shipping-address,
      .billing-address,
      .billing-address-form-content {
        div.form_element {
          margin: 0px 10px 0px 0;
          height: auto !important;
          width: 50%;
          display: inline-block;
          &.qas_container {
            width: 100%;
            input[name='qas_search'].adpl__mode-label + label {
              visibility: hidden;
            }
          }
        }
        .city-state-zip {
          .form-item {
            width: 33.3%;
          }
        }
        .country-id {
          float: none;
          height: auto;
        }
        .title {
          float: none;
          display: block;
        }
        fieldset.address.fs {
          input {
            width: 100%;
          }
          .postal_code_container {
            position: relative;
            width: 100%;
            input {
              width: 48%;
            }
            label {
              margin-bottom: 0.5em;
            }
            .fieldset-note {
              top: 0px;
              left: 324px;
              position: absolute;
              a {
                line-height: 22px;
                font-size: 12px;
                padding: 8px 12px;
                width: 220px;
                height: 40px;
                background: $color-off-black;
                color: $white;
              }
              a:hover {
                background: $white;
                color: $color-off-black;
              }
            }
            .lookup_submit_note {
              display: inline-block;
              margin-bottom: 1em;
            }
          }
          .form_element.state_container {
            .select-box {
              width: 100%;
              &__label {
                padding-top: 5px;
                height: 44px;
                &:after {
                  top: 10px;
                }
              }
              &__options {
                top: 43px;
                border-right: 2px solid $color-off-black;
              }
            }
          }
        }
        input[type='radio'] + label + span {
          display: block;
          margin: 15px 0;
        }
        .billing-address-from-fields {
          margin: 15px 0 0 0;
          input[type='radio'] {
            float: left;
            margin: 2px 0;
            width: auto;
            & ~ label {
              clear: none;
              display: inline-block;
              margin-left: 10px;
            }
          }
        }
      }
      .shipping-address-display,
      .billing-address-display {
        padding-bottom: 20px;
      }
      .edit-shipping-radio {
        margin-bottom: 20px;
        span {
          display: inline-block;
          width: 45%;
          label {
            display: inline;
            margin-left: 1em;
          }
        }
      }
      .edit-billing-radio {
        label {
          display: inline;
          margin-left: 1em;
        }
      }
    }
    .content {
      &.column,
      #gift-options {
        float: left;
        width: 45%;
        padding: 0 10px 20px 10px;
        h3 {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
    }
    &.finished {
      #shipping-address-display,
      #gift-options,
      #billing-address-display {
        width: 33%;
      }
    }
    &.adpl {
      input[type='text'],
      input[type='tel'] {
        &.js-label-mode + label {
          opacity: 1 !important;
        }
      }
      input[name='qas_search'] {
        line-height: 1;
        padding: 20px 10px;
      }
    }
    textarea {
      @include setdefault_height;
      height: calc(9.5em - -2px);
      font-size: 1em;
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      -webkit-box-shadow: none;
      box-shadow: none;
      resize: none;
      outline: 0;
      border-color: $color-light-gray;
      & + label {
        @include setdefault_height;
        @include setdefault_inputpadding;
        @include setdefault_inputmargin;
        display: block;
        font-size: 1em;
        pointer-events: none;
        height: calc(9.5em - -2px);
        line-height: 1;
        padding-top: calc(1em - -1px);
        margin-top: calc(-9.5em - 2px);
        cursor: text;
        &:before {
          @include setdefault_beforemargin;
          content: attr(data-required) attr(placeholder);
          display: inline-block;
          color: $color-light-grey-text;
          margin: 0 calc(1em - -2px);
          white-space: nowrap;
          -webkit-transition-property: -webkit-transform, color;
          transition-property: transform, color;
          -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
          -webkit-transition-delay: 0;
          transition-delay: 0;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
          -webkit-transform-origin: left center;
          -ms-transform-origin: left center;
          transform-origin: left center;
          text-transform: uppercase;
        }
        span.label-content {
          display: none;
        }
      }
      &.active + label:before,
      &.js-label-mode + label:before,
      &:focus + label:before {
        background: $white;
        line-height: 1;
        padding: 0 1.5px;
        -webkit-transform: translateY(-1.5em) translateY(-0.5px) scale(0.8, 0.8);
        transform: translateY(-1.5em) translateY(-0.5px) scale(0.8, 0.8);
      }
      &.js-label-mode + label:before {
        content: attr(data-required) attr(alt);
      }
    }
    .gift-options {
      input[type='text'],
      textarea {
        &[data-error] {
          border-color: $color-red;
        }
      }
    }
  }
  section.registration-panel {
    .messages {
      padding: 0 10px;
    }
    .content {
      .form-item {
        &.email-address {
          margin-bottom: 10px;
        }
        &.password {
          label {
            display: block;
          }
          .password {
            width: 48%;
            display: inline-block;
            margin-right: 12px;
          }
          .text {
            width: 48%;
            display: inline-block;
          }
          p.note {
            margin-top: 10px;
          }
        }
        &.password-hint {
          .select-box {
            width: 100%;
          }
        }
      }
    }
  }
  section.payment-panel {
    @include breakpoint($landscape-down) {
      .payment-edit-content {
        .continue-button-wrapper {
          width: 100%;
          input[type='submit'] {
            width: 100%;
          }
        }
      }
    }
    form#checkout_complete {
      overflow: hidden;
    }
    .expires-date__container {
      .form-item.select {
        padding-right: 10px;
        .select-box__label {
          height: 44px;
          line-height: 44px;
        }
        .select-box__label.open:after,
        .select-box__label:after {
          line-height: 45px;
        }
      }
      .select-box {
        width: 100%;
      }
    }
    .checkout-esalon {
      padding: 0 11px;
      h3.esalon__heading {
        cursor: pointer;
        font-size: 14px;
        color: $color-off-black;
        letter-spacing: 0;
        line-height: 20px;
        i {
          position: relative;
          left: 8px;
          top: 5px;
        }
      }
      .salon_id.select-box {
        width: 100%;
      }
    }
    .payment-edit {
      padding: 0 10px;
    }
    .payment-edit-content {
      padding: 0 10px;
      clear: both;
      .payment-container {
        margin-bottom: 10px;
        float: left;
        width: 100%;
        input {
          float: left;
          margin-right: 5px;
        }
        label {
          width: auto;
          float: left;
        }
      }
      .payment_gmo {
        .payment-form {
          clear: both;
          .form-item {
            &.card-number {
              width: 65%;
              margin: 20px 15px 20px 0;
              padding: 0;
            }
            &.expiry {
              margin-bottom: 1.5em;
              label {
                display: block;
              }
              .expires-month-wrapper,
              .expires-year-wrapper {
                div {
                  display: none;
                }
                select {
                  display: inline-block;
                  width: 48% !important;
                  border: 1px solid $color-light-grey-text;
                  height: 36px;
                }
              }
              .select-box {
                width: 48% !important;
              }
            }
          }
        }
      }
    }
  }
  .adpl {
    div {
      &.selectBox {
        border-width: 0;
      }
    }
  }
  .password-description {
    display: none;
  }
}

#index {
  &.checkout {
    position: static;
  }
  .checkout__index-content {
    margin-top: 0;
    .checkout__sidebar {
      .cart-item {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $light-gray;
      }
    }
  }
  fieldset {
    @include clearfix;
    border: 0;
    margin: 0;
    padding: 0;
    clear: both;
    width: 100%;
    max-width: 664px;
    margin-left: 0;
  }
  .form-item {
    input[type='text'],
    input[type='email'],
    input[type='url'],
    input[type='password'],
    input[type='tel'],
    input[type='number'],
    input[type='search'],
    input[type='date'],
    input[type='time'],
    select,
    textarea {
      width: 100% !important;
      background: $white;
    }
    .button {
      margin-left: 1px;
      width: 100%;
      cursor: pointer;
      label {
        display: block;
        cursor: pointer;
      }
    }
    .select-box__label {
      height: 35px;
      line-height: 35px;
    }
  }
  .links-panel {
    li.link {
      a.overlay-link {
        margin-bottom: 0;
      }
    }
  }
  .adpl {
    input[type='email'],
    input[type='tel'],
    input[type='password'],
    input[type='text'],
    select {
      &.js-label-mode {
        & + label:before {
          background: $white;
          line-height: 1;
          padding: 0 1.5px;
          -webkit-transform: translateY(-1.5em) translateY(-0.5px) scale(0.8, 0.8);
          transform: translateY(-1.5em) translateY(-0.5px) scale(0.8, 0.8);
        }
      }
    }
  }
  #gift-max-char {
    clear: both;
    display: block;
  }
  .gift-card-entry-container {
    input[type='text'] + label:before,
    input[type='password'] + label:before {
      content: attr(data-required) attr(placeholder);
    }
  }
  .checkout-single-page {
    .checkout {
      a.js-afterpay-learnmore-mobile {
        display: none;
      }
    }
    li {
      &.link {
        a {
          &.overlay-link {
            border: 0;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

#confirmation-page {
  margin-top: 0px;
  .checkout-registration__show-password {
    display: none;
  }
  header {
    padding: 0px 0 18px 0;
    h2 {
      font-size: 41px;
      letter-spacing: -2px;
      text-transform: uppercase;
    }
  }
  .content {
    p:nth-child(3) {
      font-size: 19px;
      a {
        font-weight: bold;
      }
    }
  }
  .confirmation-panel {
    width: 70%;
  }
  .confirmation-panel__actions {
    margin-left: 0;
    float: left;
    width: 100%;
    .print-buttons {
      float: left;
      .receipt-print-btn {
        background-color: $color-off-black;
        color: $white;
        width: auto;
        padding: 9px 19px 8px 19px;
        text-transform: uppercase;
      }
    }
    .return-link-container {
      float: right;
      .button {
        background-color: $color-off-black !important;
        border: none;
        border-radius: initial;
        padding: 9px 19px 8px 19px !important;
        line-height: 19px !important;
        height: auto;
        &:hover {
          color: $white !important;
        }
      }
    }
  }
  .column {
    &.right.checkout__sidebar {
      position: absolute;
      top: 3%;
      right: 0;
      width: 30%;
      .need-help-panel__title,
      .links-panel__title {
        margin: 0;
        padding: 0;
        font-size: 27px;
      }
    }
  }
  .checkout-panel__content,
  .checkout__panel-content {
    &.checkout-panel__salon--header {
      padding: 0;
      margin: 0;
      float: left;
      width: 100%;
      h3 {
        border-top: 1px solid $light-gray;
        font-weight: bold;
        padding: 15px 0;
        text-transform: uppercase;
      }
    }
    &.checkout-panel__salon {
      float: left;
      width: 33.3%;
      h3 {
        font-weight: bold;
      }
      span {
        display: block;
      }
    }
    #completed_checkout_registration {
      .form-item {
        height: auto;
      }
      .checkout-password-label {
        display: none;
      }
    }
  }
  .form-item {
    &.password-description {
      display: none;
    }
  }
  .right.checkout__sidebar {
    border-top: 0;
    .need-help-panel {
      border-bottom: 1px solid $color-light-grey;
    }
    .links-panel {
      a.overlay-link {
        margin-bottom: 3px;
      }
    }
  }
}

.column {
  &.right.checkout__sidebar {
    section.panel {
      padding-bottom: 20px;
      header {
        padding-top: 5px;
      }
    }
    &.display-footer {
      display: none;
    }
    #viewcart-panel {
      #top-viewcart-buttons {
        display: none;
      }
    }
    .promo-bca-content {
      display: none;
    }
  }
}

.checkout__panel-title,
.checkout__subtitle {
  font-size: 28px;
  line-height: 30px;
  margin: 10px 0;
  letter-spacing: -1px;
  font-weight: normal;
  display: inline-block;
  padding: 0;
  text-transform: uppercase;
  span {
    display: block;
  }
}

.checkout-esalon {
  line-height: 2;
  .esalon {
    &__content {
      padding: 0px !important;
      overflow: visible !important;
    }
  }
  h3 {
    font-size: 28px;
    line-height: 30px;
    letter-spacing: -1px;
    text-transform: uppercase;
    margin: 15px 0;
  }
  .select-box__label {
    max-width: 664px;
  }
  .esalon__salon-finder-link {
    text-decoration: underline;
  }
}

.checkout {
  @include clearfix;
  position: relative;
  margin: 0 auto 40px;
  max-width: $max-width;
  z-index: 1;
  font-size: 14px;
  margin-top: 124px;
  height: 350px;
  &-header {
    position: relative;
    text-transform: uppercase;
    width: 100%;
  }
  &-header__title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 0.48em;
    @include breakpoint($landscape-up) {
      font-size: 38px;
      text-align: left;
    }
  }
  .sign-in-paypal-panel,
  .sign-in-panel {
    &:after {
      content: '.';
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
  &__content {
    @include breakpoint($landscape-up) {
      float: left;
      width: 64%;
      border-top: 1px solid $color-light-gray;
    }
    .review-panel--header {
      display: none;
    }
    #viewcart-panel {
      .single-message {
        color: $color-red;
      }
    }
    fieldset {
      width: auto;
      max-width: none;
      margin-right: 0;
      &.fs {
        max-width: 45em;
        width: 100%;
      }
    }
    .address-options {
      @include breakpoint($landscape-up) {
        width: 33.3333333%;
      }
      &__address-container {
        min-height: 120px;
      }
      &__select-this-address {
        line-height: 18px;
        padding: 10px;
        // Hide --selected span at first
        .select-label--selected {
          display: none;
        }
        // If it's the the selected address, show --selected and hide the normal span
        &.selected {
          .select-label {
            display: none;
            &--selected {
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .hazmat {
    p {
      display: none;
    }
  }
  // BOGO OfferCode Styles
  .bogo-offer {
    .cart-item {
      &__replenishment,
      &__remove-form,
      &__add-to-favorites {
        display: none;
      }
    }
  }
}

.product_name {
  font-weight: 700;
  text-transform: uppercase;
  a {
    text-decoration: none;
  }
}

.select-box {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  min-width: 150px;
  vertical-align: middle;
}

.accordionPanel {
  header {
    cursor: pointer;
  }
  i {
    float: right;
    font-family: 'icons';
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:before {
      content: map-get($icons, caret--down);
    }
  }
  .active i {
    &:before {
      content: map-get($icons, caret--up);
    }
  }
}

#samples-pane,
#index,
#confirmation-page {
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 25% 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: $white;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=00);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=00)';
    -webkit-transition: opacity 3s ease-in-out;
    -moz-transition: opacity 3s ease-in-out;
    -ms-transition: opacity 3s ease-in-out;
    -o-transition: opacity 3s ease-in-out;
    transition: opacity 3s ease-in-out;
    &.fade-in {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
    }
    &:before {
      display: inline-block;
      margin-right: -0.25em;
      height: 100%;
      vertical-align: middle;
    }
    .text {
      display: inline-block;
      padding-top: 50px;
      width: 100px;
      vertical-align: middle;
    }
  }
  .messages {
    background-color: transparent;
    color: $red;
    float: left;
    width: 100%;
    border-bottom: none;
    padding: 0 10px;
  }
}

.checkout {
  .panel:not(.viewcart-panel) {
    #error_offer_bogo {
      display: none;
    }
  }
}

.bogo-offer {
  .cart-item__qty {
    text-align: center;
  }
}
.engraving_option {
  clear: both;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  background-color: $color-light-br;
  .engraving-cart-control {
    margin: 2em;
    .engraving-button-wrapper {
      padding: 0;
      float: right;
      .engraving-show-toggle,
      .engraving-toggle-cancel {
        background-color: $color-dark-yellow;
        color: $color-light-br;
      }
    }
    .p {
      .engraving_option_text {
        margin: 0;
      }
    }
  }
  &.engraving_restrict {
    background-color: $color-wh;
  }
}
.engraving-wrapper {
  background-color: $color-light-br;
  .engraving {
    &-edit {
      .engraving-row {
        background-color: $color-light-br;
        .engraving-label {
          display: block;
          text-align: center;
          background-color: $color-light-br;
        }
      }
      .background-color {
        .engraving-form-element-content {
          margin: 20px 10px 10px;
          .engraving-form-template {
            .monogram-error {
              color: $color-red;
              padding-bottom: 10px;
            }
            .monogram_custom_text {
              width: 50%;
              float: left;
              margin-left: 25%;
              input.engraving-message {
                width: 100%;
              }
              label {
                margin-bottom: 5px;
              }
              .engraving-message-count {
                display: block;
                clear: both;
                margin-bottom: 20px;
              }
            }
            .monogram_custom_image_right {
              margin-left: 2%;
              width: 35%;
              height: 35px;
              .select-box__label {
                height: 35px;
              }
            }
          }
          .description {
            color: #000;
            margin-bottom: 1em;
            b {
              font-weight: normal;
            }
            .note-wrap {
              margin-top: 10%;
              margin-left: 9%;
              .note-text {
                margin-bottom: 0;
                margin-left: 1.2em;
                padding: 0.5em;
                text-indent: -1.2em;
                font-size: 0.7rem;
                line-height: 1.25;
              }
            }
          }
          .action-buttons-div {
            width: 100%;
            height: 40px;
            margin: 10px 0 20px 0;
            clear: both;
            .engraving-save,
            .engraving-toggle-cancel {
              display: block;
              color: $color-dark-yellow;
              border: 2px solid $color-dark-yellow;
              float: none;
              padding: 12px;
              margin: auto;
              text-decoration: none;
              text-align: center;
              width: 30%;
            }
            .engraving-toggle-cancel {
              background-color: $color-light-br;
              margin-top: 10px;
            }
          }
        }
        .engraving-form-element-footer {
          .monogram_left_image {
            width: 48%;
            margin-left: 26%;
          }
          .monogram_right_image {
            width: 38%;
            margin-left: 3%;
          }
        }
        .engraving-service-text {
          margin: 0;
          padding: 1em;
          background-color: $color-red-orange;
          color: $color-wh;
        }
      }
    }
    &-view {
      border-width: 1px;
      border-color: $color-dulux;
      border-top: 2px solid $color-lightgrey;
      padding-top: 1em;
      height: 230px;
      &-title {
        margin: 10px;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0;
      }
      &-message {
        text-align: center;
        border: 1px solid $color-hexgrey;
        cursor: pointer;
        font-size: 26px;
        height: 65px;
        background: $color-wh;
        clear: both;
        width: 55%;
        margin: 0 5%;
        float: left;
        pointer-events: none;
        &-content {
          overflow: auto;
          overflow-y: hidden;
          div {
            white-space: pre;
            word-wrap: normal;
            font-size: 18px;
            line-height: 65px;
            vertical-align: middle;
            .monogram_custom_text {
              font-size: 20px;
              padding: 0;
              display: inline-block;
              white-space: pre;
              width: 50%;
              float: left;
              margin-left: 0;
              text-align: right;
              font-family: Calibri;
            }
            img {
              width: 17%;
              margin-bottom: 4px;
              margin-left: 0;
            }
          }
        }
      }
      &-control {
        margin-top: 13px;
        float: left;
        form {
          margin: 0;
        }
        .engraving-toggle-edit {
          display: block;
          margin-top: 10px;
        }
      }
      &-footer {
        float: left;
        margin: 3% 0 3% 5%;
        .note-wrap {
          margin: 1vh auto;
          text-align: left;
          .note-text {
            margin-bottom: 0;
            margin-left: 1.2em;
            padding: 0.5em;
            text-indent: -1.2em;
            font-size: 0.7rem;
            line-height: 1.25;
          }
        }
      }
    }
  }
}
#index {
  .engraving {
    &-view {
      height: 135px;
      border-top: none;
      &-message {
        width: 90%;
      }
    }
    &-delete {
      margin-bottom: 0;
    }
    &-view-message-content {
      div {
        img {
          margin-bottom: 18px;
        }
      }
    }
    &-view-control {
      display: none;
    }
    &-view-footer {
      display: none;
    }
    &_sku {
      .cart-item {
        &__product-name {
          a {
            border: none;
          }
        }
      }
    }
    &-cart-control {
      display: none;
    }
  }
  .cart-items {
    .product {
      &__thumb {
        min-height: 150px !important;
      }
    }
  }
}
#order_detail {
  .cart-item {
    &__monogram-text {
      display: inline;
      padding-left: 5px;
      img {
        width: 11%;
      }
    }
    &__monogram-price {
      display: block;
      padding-left: 65px;
      width: 233px !important;
    }
    &__products {
      padding-right: 0;
    }
    &__monogram {
      width: 200px;
      &-label {
        display: inline;
        padding-left: 5px;
      }
    }
    &.sample {
      &.engraving_service_sku {
        display: none;
      }
    }
  }
}
.order-confirmation {
  .cart-item {
    &__monogram-label {
      display: inline;
    }
    &__monogram-text {
      display: inline;
    }
  }
}
.site-header__main {
  .gnav-util {
    .engraving_motif {
      img {
        width: 15%;
      }
    }
  }
}
