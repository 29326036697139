.account-section {
  .profile-info {
    .optional-info {
      &__item {
        margin-bottom: 10px;
      }
    }
  }
  .newsletter-info {
    .newsletter-info {
      &__text {
        &--new {
          width: 100%;
        }
      }
    }
  }
  .customer-service-content {
    a {
      text-decoration: underline;
    }
  }
  &.order-details-page {
    .order-total {
      width: 47%;
      table {
        tr {
          td {
            padding-left: 12%;
          }
        }
      }
    }
  }
  .section-content {
    span {
      &.trade {
        font-size: 50%;
        line-height: 0;
      }
    }
    .past-purchase {
      .product-item {
        &__name {
          min-height: 50px;
        }
      }
    }
  }
  .favorites-page {
    &__content {
      .product-info {
        &__name {
          min-height: 50px;
        }
      }
    }
  }
}

.address-book-page {
  &__overlay {
    .address-overlay {
      .address-form {
        .required.form-item {
          width: 100%;
          padding-bottom: 10px;
        }
        .phone-required {
          width: 100%;
          padding-bottom: 6px;
        }
        .title-fields {
          float: left;
          width: 100%;
          label {
            .label-content {
              width: 7%;
            }
          }
          span {
            width: 11%;
            padding-bottom: 10px;
            float: left;
          }
        }
      }
      .address {
        &.qas {
          padding-bottom: 25px;
          clear: both;
        }
      }
      .city-state-zip {
        .postal-code {
          width: 100%;
        }
        .state {
          label {
            display: none;
          }
          .select-box {
            width: 100%;
          }
        }
      }
    }
    &#colorbox {
      #cboxClose {
        right: 18px;
      }
    }
  }
}

.address-book {
  &__content {
    .section-head {
      text-transform: uppercase;
    }
  }
}

.signin-block {
  .pp_birth_day_container {
    #form {
      &--pure_priv_account_link {
        &--field {
          &--PURE_PRIV_BIRTH_DAY {
            float: left;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .new-account {
    &__fieldset {
      .form_element {
        &.pp_birth_day_container {
          .birthday_date {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .signin-block {
    &__forms {
      .signin-block {
        &__checkbox {
          .label {
            width: 96%;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

.welcome15-overlay {
  .welcome-15 {
    &.success {
      .site-email-signup {
        &__success-header {
          margin-left: 60px;
        }
        &__success-offer {
          margin-left: 65px;
        }
        &__success-cta {
          margin-bottom: 10px;
        }
        &__success-reminder {
          margin-top: 10px;
          margin-left: 71px;
        }
      }
    }
  }
}

.competition_form_container {
  .error {
    color: $red;
  }
  .my_emailaddress {
    @-moz-document url-prefix() {
      label {
        line-height: 2em;
      }
    }
  }
}
/* Auto-Replenishment */
.replenishment-detail-page {
  &__content {
    .select-box__label {
      text-overflow: ellipsis;
    }
  }
}
