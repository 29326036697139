.offer-block-formatter {
  .site-email-signup__form {
    .site-email-signup__message {
      .site-email-signup__message__error__generic {
        margin-top: -20px;
      }
    }
  }
}

.viewcart {
  .checkout__content {
    .product-list {
      .recommended-item {
        height: auto !important;
      }
    }
  }
}

#index {
  .checkout {
    #checkout_shipping_panel {
      .title-fields {
        label {
          float: left;
          width: 11%;
        }
        .form-item {
          display: inline-block;
          width: auto;
        }
      }
      .form-item {
        &.country-id {
          width: 100%;
          .country_select {
            width: 50%;
          }
        }
        &.default-shipping {
          width: 100%;
          margin-bottom: 0px;
        }
        width: 50%;
        float: left;
        padding-right: 1.33em;
      }
      .continue-button-wrapper {
        float: left;
      }
    }
  }
}

#address_book {
  .ui-widget-content {
    width: 710px !important;
    .ui-state-hover {
      background: #dadada !important;
      border: none !important;
      color: #212121;
    }
  }
}

.spp__container {
  .product-full__details {
    .product-points {
      display: none;
    }
  }
  .instore-inventory-container {
    .product-store-check {
      &__inline--container {
        border: 0;
      }
    }
    .find-in-store {
      border: 1px solid $color-off-black;
      width: 100%;
      padding: 5px 0;
      display: inline-block;
      text-align: center;
      font-size: 16px;
      margin: 10px 0;
      cursor: pointer;
    }
    .location-controls {
      &__btn {
        border: none;
      }
    }
  }
  .product-full {
    &__actions {
      border-top: 1px solid $black;
      border-bottom: 1px solid $black;
      padding: 10px 0;
    }
  }
}

.trustmark_logo {
  margin: 0 auto;
  width: 900px;
  padding: 50px 0;
  .trustmark_img {
    float: right;
    width: 29%;
  }
  .trustmark_content {
    width: 62%;
  }
}

#order_detail {
  .order-details-page {
    max-width: 790px;
  }
}

.order-confirmation {
  .account-section__sidebar {
    display: none;
  }
  .order-details-page__content {
    width: 100%;
  }
}

.store-locator__results-container {
  .store-locator__tooltip-product-types {
    text-transform: uppercase;
    font-size: 12px;
    .pc {
      padding: 2px 2px 0px 2px;
      &.pc {
        &--tf {
          background-color: #632522;
          padding: 2px 4px 0px 4px;
        }
      }
    }
  }
}

#confirm {
  .checkout--confirmation-page {
    #registration-panel {
      .registration-content__registration-form-container {
        .checkout-registration__password {
          width: 100%;
          .password {
            width: 30%;
            float: left;
          }
          .note {
            width: 100%;
            float: left;
          }
        }
      }
    }
  }
  .registration-content {
    .accepted-privacy-policy {
      input {
        width: 4%;
      }
      .label {
        width: 92%;
        margin-left: 6px;
        a.terms_privacy {
          margin-bottom: 0;
        }
        &.error {
          a.terms_privacy {
            text-decoration: underline;
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.registration-content {
  .accepted-privacy-policy {
    label {
      &.error {
        span.label-content,
        a.terms_privacy {
          color: $red;
        }
      }
    }
  }
}

.site-footer {
  .site-email-signup__terms-conditions {
    &__checkbox,
    &__more {
      font-size: 10px;
    }
  }
  &__sticky {
    #AV_AU_LiveChat {
      a {
        background: $color-off-black;
        text-decoration: underline;
      }
    }
    &--offer {
      left: 260px;
    }
  }
}

.on-sale {
  color: $color-off-black;
}

.cs-page {
  &__content-item {
    #contact_us {
      .fieldset {
        .select-box__label {
          color: $color-off-black;
        }
      }
    }
  }
}

.store-locator {
  &__touts {
    .basic-grid {
      &__item {
        width: 33%;
        .basic-tout {
          .text--small {
            height: 130px;
          }
        }
      }
    }
  }
}

.welcome-15 {
  &.success {
    .site-email-signup {
      &__success-reminder {
        font-size: 13px;
      }
    }
  }
}

.ecommerce {
  .site-content {
    .product-brief {
      &__quickshop-launch {
        left: 50%;
        transform: translateX(-50%);
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

body {
  &.ajax-wait-inventory,
  &.ajax-wait-inventory * {
    cursor: progress !important;
  }
}

.spp-content {
  .product-full {
    .p-w-r {
      .pr-review-snippet-container {
        float: none;
      }
    }
    .product-shade-picker {
      width: 100%;
    }
    .product__inventory-status {
      text-align: #{$rdirection};
      margin-top: 10px;
    }
    .product-info-area__header {
      .icon {
        &:after {
          padding-bottom: 10px;
        }
      }
    }
  }
}

.lpdv {
  .lp-window-root {
    .lp_survey_area {
      .lp_pages_area {
        span {
          &.lp_radio_button {
            display: flex;
          }
        }
      }
    }
  }
}

.salon-takeover {
  .getthefacts {
    &__store_meta_image {
      width: 50%;
      float: left;
    }
    &__store_meta_info {
      width: 50%;
      float: right;
    }
    &__services_column {
      width: 25%;
      float: left;
    }
  }
}

.tabbed-block {
  &__text {
    text-align: center;
  }
  &__tabs {
    margin: 20px auto;
    display: table;
    padding: 20px;
  }
  &__tabs-item {
    padding: 9px 19px 8px 19px;
    line-height: 16px;
    border: 1px solid $brown;
  }
}

.social-gallery {
  &__header {
    text-align: center;
  }
  &__title {
    font-size: 38px;
  }
}

.adv_cal_cap {
  .adv-cal-cap-container {
    .adv-cal-cap-popup {
      .popup-content-wrapper {
        .offer-details {
          &__img,
          &__content,
          &__controls {
            float: unset;
            text-align: center;
          }
        }
      }
    }
  }
}
